import { RoleState, IRole } from '../../types';
import roleState from '../states/roles';
import { SET_ROLE } from '../actions/actionTypes';

const STORE_INIT_CONSTANTS = {
  role: roleState,
};

const rolesReducer = (
  role: RoleState = STORE_INIT_CONSTANTS.role,
  action: { type: string; payload: IRole }
): RoleState => {
  switch (action.type) {
    case SET_ROLE:
      return { role: action.payload };
    default:
      break;
  }
  return role;
};

export default rolesReducer;
