import { useEffect, useState } from 'react';
import {
  FaDiscord,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaYoutube,
  FaSearch,
  FaExternalLinkSquareAlt
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { TbWorld } from 'react-icons/tb';
import axios from 'axios';
import { useRef } from 'react';
import { CircularProgress } from '@mui/material';
import useVisible from '../../hooks/useVisible';
import { useTranslation } from 'react-i18next';
import cultureyeslogo from '../../assets/culture-yes.png';

let filter_params = [
  '',
  '#',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const Artists = () => {
  const triggerRef = useRef(null);
  const inViewport = useVisible(triggerRef, '0px'); // Trigger as soon as the element becomes visible

  const { t } = useTranslation();

  const baseURL = 'https://advocates.monstermy.city/influencers';

  const [filterSelect, setFilterSelect] = useState('');
  const [influencers, setInfluencers] = useState<any>([]);
  const [selectOption, setSelectOption] = useState(0);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const [nextRequest, setNextRequest] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [textFilter, setTextFilter] = useState("");

  const openLinkOpensea = (name: string) => {
    window.open(`https://opensea.io/collection/monstermycity?search[stringTraits][0][name]=For%20the%20Culture&search[stringTraits][0][values][0]=${encodeURIComponent(name.trim())}`, "_blank");
  }

  const getInfluencers = (full_search: string) => {
    axios
      .get(
        baseURL +
        `?for_the_culture=${selectOption === 1}` +
        (full_search !== '' ? `&full_search=${full_search}`
          : (filterSelect !== '' ? `&search=${filterSelect === '#' ? encodeURIComponent('#') : filterSelect}`
            : ''))
      )
      .then(async (response) => {
        let influencers: any = []
        let next = null;
        let hasMore = false;
        if (response.data) {
          influencers = [...influencers, ...response.data.results]
          next = response.data.next
            ? response.data.next.replace('http:', 'https:')
            : null;
          hasMore = response.data.results < response.data.count;

          for (let index = 0; index < 3; index++) {
            if (next) {
              const req: any = await axios.get(next);
              if (req.data) {
                influencers = [...influencers, ...req.data.results]
                next = req.data.next
                  ? req.data.next.replace('http:', 'https:')
                  : null;
                hasMore = req.data.results < req.data.count;
              }
            }
          }
          influencers = influencers.filter((i: any) => !!i).map((i: any) => {
            i.social_media = "";
            if (i.social_links.length > 0) {
              const url = i.social_links[0].url.split("/");
              i.social_media = getNameFromUrl(i.name, i.social_links, url)
            }
            return i;
          })
          if (filterSelect === "") {
            influencers = influencers.sort(() => Math.random() - 0.5)
          }
          setInfluencers(influencers);
          setNextRequest(next);
          setHasMoreItems(hasMore);

        }
      });
  };
  const loadMoreItems = () => {
    if (nextRequest) {
      axios.get(nextRequest).then((response) => {
        if (response.data) {
          const moreInfluencers = response.data.results.filter((i: any) => !!i).map((i: any) => {
            i.social_media = "";
            if (i.social_links.length > 0) {
              const url = i.social_links[0].url.split("/");
              i.social_media = getNameFromUrl(i.name, i.social_links, url)
            }
            return i;
          })

          const data = [...influencers, ...moreInfluencers];
          setInfluencers(data);
          setNextRequest(
            response.data.next
              ? response.data.next.replace('http:', 'https:')
              : null
          );
          setHasMoreItems(data.length < response.data.count);
        }
      });
    }
  };
  const getNameFromUrl = (name: string, social_links: any, url: string) => {
    let username = "";
    if (social_links[0].url.toLowerCase().includes("www.facebook.com/groups")) {
      username = "@groups"
    } else if (social_links[0].platform.toLowerCase() !== "website") {
      username = social_links[0].url.endsWith("/") ? url[url.length - 2] : url[url.length - 1]
    } else {
      username = url[2]

    }
    return username.startsWith("@") ? username : `@${username}`;
  }

  useEffect(() => {
    if (inViewport) {
      loadMoreItems();
    }
  }, [inViewport]);

  useEffect(() => {
    if (inputValue === "") {
      getInfluencers("");
    }
  }, [filterSelect, selectOption]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (inputValue !== "") setFilterSelect("")
      getInfluencers(inputValue)
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [inputValue, 1000]);

  useEffect(() => {
    const scrollContainer = document.getElementById('scroll-container');

    if (scrollContainer) {
      const handleOverscroll = (e: any) => {
        const scrollTop = scrollContainer.scrollTop;
        const scrollHeight = scrollContainer.scrollHeight;
        const clientHeight = scrollContainer.clientHeight;

        if (scrollTop === 0) {
          const safetyElement = document.getElementById('safety');
          if (safetyElement) {
            safetyElement.scrollIntoView({ behavior: 'smooth' });
          }
        }
      };

      scrollContainer.addEventListener('touchmove', handleOverscroll);

      // Cleanup the event listener when the component unmounts
      return () => {
        scrollContainer.removeEventListener('touchmove', handleOverscroll);
      };
    }
  }, []);

  return (
    <>
      <div id="artists" className="artists">
        {/*<div className='artists-title'>
          <button className={selectOption === 0 ? "active" : ""} onClick={() => setSelectOption(0)}>{t('WEB 3 CREATORS')}</button>
          <button className={selectOption === 1 ? "active" : ""} onClick={() => setSelectOption(0)}>{t('FOR THE CULTURE')}</button>
        </div>*/}
        <div className="artists-title">
          <input
            id="tab1"
            type="radio"
            name="tabs"
            checked={selectOption === 0}
          />
          <label onClick={() => { setInputValue(""); setSelectOption(0) }}>{t('CREATORS')}</label>

          <input
            id="tab2"
            type="radio"
            name="tabs"
            checked={selectOption === 1}
          />
          <label
            onClick={() => {
              setInfluencers([]);
              setHasMoreItems(false);
              setNextRequest(null);
              setInputValue("");
              setSelectOption(1);
            }}
          >
            {t('FOR THE CULTURE')}
          </label>
        </div>

        <div className="artists-container">
          <div className="filter">
            <div className="controls">
              {filter_params.map((p, k) => (
                <button
                  key={k}
                  className={p === filterSelect ? 'active' : ''}
                  onClick={() => { setInputValue(""); setFilterSelect(p) }}
                >
                  {p === '' ? <GoHome /> : p}
                </button>
              ))}
            </div>
          </div>
          <div className="artists-section">
            <div className='search'>
              <div className='input-container'>
                <FaSearch />
                <input
                  type="text"
                  placeholder="Search"
                  value={inputValue}
                  onChange={(e: any) => {
                    setInputValue(e.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="artists-items" id="scroll-container">
              {influencers.length > 0 &&
                influencers.map(
                  (i: any, index: number) =>
                    i && (
                      <div key={index} className="item">
                        <img src={i.profile_picture} alt="" />
                        <div className="img-cover">
                          {selectOption === 1 && (
                            <img
                              className="culture-toggle-yes"
                              src={cultureyeslogo}
                              alt="culture-yes"
                            />
                          )}
                          <div className="social-links">
                            {i.social_links.map((s: any, index: number) => (
                              <a
                                key={index}
                                href={s.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {s.platform.toLowerCase() === 'twitter' && (
                                  <FaXTwitter />
                                )}
                                {s.platform.toLowerCase() === 'instagram' && (
                                  <FaInstagram />
                                )}
                                {s.platform.toLowerCase() === 'youtube' && (
                                  <FaYoutube />
                                )}
                                {s.platform.toLowerCase() === 'linkedin' && (
                                  <FaLinkedin />
                                )}
                                {s.platform.toLowerCase() === 'discord' && (
                                  <FaDiscord />
                                )}
                                {s.platform.toLowerCase() === 'tiktok' && (
                                  <FaTiktok />
                                )}
                                {s.platform.toLowerCase() === 'facebook' && (
                                  <FaFacebook />
                                )}
                                {s.platform.toLowerCase() === 'website' && (
                                  <TbWorld />
                                )}
                              </a>
                            ))}
                          </div>
                          {selectOption === 1 ?
                            <div onClick={() => openLinkOpensea(i.name + ` ${i.social_media ? i.social_media : i.social_names[0]}`)} className='culture'>
                              <div className="name">{i.name}</div>
                            </div>
                            : <div className='creators'>
                              <div className="name">{i.name}</div>
                            </div>}
                        </div>
                      </div>
                    )
                )}
              <div ref={triggerRef}>
                {hasMoreItems && <CircularProgress />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Artists;
