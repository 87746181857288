import { createStore, Store, applyMiddleware } from 'redux';
import { persistReducer, createTransform } from 'redux-persist';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { stringify, parse } from 'flatted';

import { State } from '../types';
import rootReducer from './reducers';

// redux-persist generates error in los with circular objects this transform is used to fix it
export const TransformCircular = createTransform(
  (inboundState, key) => stringify(inboundState),
  (outboundState, key) => parse(outboundState)
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['_getERC20Balance'],
  transforms: [TransformCircular],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store: Store<State> = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
