import { AccountState, IAccount } from '../types';
import { useSelector, shallowEqual } from 'react-redux';

export const useAccount = () => {
  const account: IAccount = useSelector(
    (state: AccountState) => state.account,
    shallowEqual
  );

  return account;
};
