import { AlertState, IAlert } from '../types';
import { useSelector, shallowEqual } from 'react-redux';

export const useAlert = () => {
  const message: IAlert = useSelector(
    (state: AlertState) => state.alert,
    shallowEqual
  );

  return message;
};
