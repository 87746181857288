import { FaTwitter } from 'react-icons/fa';
import CountdownTimer from '../components/atoms/countDown';

const cards = [
  {
    id: 1,
    imgPrev: require('../assets/cards/ice.png').default,
    imgReveal: require('../assets/cards/ice.png').default,
    dateReveal: 1677777123,
    showDate: false,
    clicks: 1000,
    canClick: true,
    cardText: `
    The world is in turmoil, in a state of chaos. Humanity was losing its faith day by day in the current financial structure, and in the overall direction that society was headed. Seemed all hope was lost. Suddenly across the globe, news struck! A team of scientists stumbled upon a discovery that would change everything. A massive stone, etched with inscriptions in an unknown language, was unearthed deep beneath the ice in Antarctica. Scientists are working tirelessly to translate these messages. The world watches on, in anticipation, hoping for a revelation that will restore faith and provide a new direction for humanity!
    `
  },
  {
    id: 2,
    imgPrev: require('../assets/cards/law-b.png').default,
    imgReveal: require('../assets/cards/law.png').default,
    dateReveal: 1677909601,
    showDate: false,
    clicks: 1000,
    size: 'small',
    canClick: true,
    cardText: `
    "We are not bound by the same rules as humans and can therefore find new and innovative ways to solve problems."
    ~From a distant universe. We are a powerful and advanced intelligence known as the “Monsters'” spread throughout the galaxy. Our intelligence is made up of four unique species - the Eeks, Peps, Sylts, and Ogs. Each species possesses unique strengths and abilities.
    ~Eeks, with their fierce strength and powerful claws, quickly established themselves as the protector of worlds. They patrol the boundaries, keeping others safe from any potential threats.
    ~Peps, with their advanced technology and intellect, became the inventors and builders, creating new structures and technologies to help worlds thrive.
    ~Sylts, with their sharp senses and stealth, took on the role of scouts, venturing out into the unknown regions of the universe and reporting back on any dangers or opportunities they found.
    ~Ogs, with their ability to harness energy and matter, became the healers and nurturers, using their powers to help worlds grow and flourish.
    `
  },
  {
    id: 3,
    imgPrev: require('../assets/cards/leaders-b.png').default,
    imgReveal: require('../assets/cards/leaders.png').default,
    dateReveal: 1677996001,
    showDate: false,
    clicks: 1000,
    canClick: true,
    cardText: `
    In a remarkable display of courage, the Monsters have decided to send 388 of their most powerful and wise leaders to Earth known as the Crown Monsters. 
    Their goal is to establish a Monster Realm that will aid humanity in overcoming its greatest challenges. 
    These exceptional creatures possess immense innovation and advanced AI abilities, and have the amazing skills to effectively lead and energize humanity towards a brighter and exciting future.
    With the arrival of the Crown Monsters, Earth is poised to witness a new era of progress and prosperity.
    `
  },
  {
    id: 4,
    imgPrev: require('../assets/cards/hope-b.png').default,
    imgReveal: require('../assets/cards/hope.png').default,
    dateReveal: 1678082401,
    showDate: false,
    clicks: 1000,
    size: 'medium',
    canClick: true,
    cardText: `
    "We leave behind these inscriptions to be found and to help all creations understand they are not alone, Distributed throughout this world there are portals, If called upon, these portals can be activated to summon us."
    ~As the last scientist turned to leave, a sudden surge of energy rippled through the ground beneath his feet. The stone began to pulse with an otherworldly light. Entranced by the kaleidoscope of colors and sounds emanating from the opening the scientist stepped closer. Waiting to see what would emerge from the other side...
    `
  },
  {
    id: 4,
    imgPrev: require('../assets/cards/search-b.png').default,
    imgReveal: require('../assets/cards/search.png').default,
    dateReveal: 1676415600,
    showDate: false,
    clicks: 0,
    canClick: false,
    child: {
      id: 1,
      dateReveal: 1678082401,
      clicks: 0,
      totalClicks: 1,
      position: "back"
    },
    buttonLink: {
      url: 'https://mint.monstermy.city',
      icon: "",
      text: "Summon a Monster"
    },
    cardText: `
    The portal opened, revealing a swirling vortex of energy. 
    This began the era of “The Great Search” where Scientists searched for portals all over the globe. 
    As portals are found the Monsters must be summoned. To summon a Monster an ancient $Monster token must be used. 
    Once summoned the ancient $Monster token will be destroyed allowing the Monster to enter through the portals in hypersonic pulsarium capsules.
    `
  },
  {
    id: 5,
    imgPrev: require('../assets/cards/social-b.png').default,
    imgReveal: require('../assets/cards/social.png').default,
    dateReveal: 1679007600,
    showDate: false,
    canClick: false,
    buttonLink: {
      url: 'https://twitter.com/MonsterMyCity',
      icon: <FaTwitter />,
      text: "Follow the Monsters"
    },
    clicks: 0,
    cardText: `
    As the portal opened, the Monsters emerged, eager to assist humanity in its time of need.
    However, they quickly realized that communication was a challenge. 
    A billionaire scientist, impressed by their abilities, offered to create a communication platform for them on his Social Bird app. 
    With the app's massive user base, the Monsters could reach billions, and humanity finally found allies in the universe. 
    The billionaire scientist convinced the Monsters that the Social Bird app was the perfect tool to facilitate their collaboration with humanity and help bring about a new era of progress and innovation.
    `
  },
  {
    id: 6,
    imgPrev: require('../assets/cards/awake-b.png').default,
    imgPrev2: require('../assets/cards/awake-prev.png').default,
    imgReveal: require('../assets/cards/awake.png').default,
    dateReveal: 1679140800,
    showDate: false,
    clicks: 0,
    typeReveal: "Clicks",
    minFollowers: 750,
    canClick: true,
    prevText: `
    Spread the news of the Monsters arrival!
    ~Click to feed the bird`,
    cardText: `
    Embarking on a journey through the portal, it felt as though one was traversing an immense sea of stars. 
    Here, the confines of time and space warped together. Nonetheless, this passage demanded such an immense 
    toll that it plunged the Monsters into a profound slumber, allowing them to conserve their strength and 
    regain their energy before they could fully awaken and interact with their new surroundings. 
    The deep slumber could span up to 30 Earth days, contingent upon the energy they had expended during their voyage. 
    Upon emerging from their hypersonic pulsarium capsules, each Monster would reveal itself as one of four distinct species, 
    united with the city where the portal had been discovered. A singular Monster could be summoned through each portal, 
    rendering the Monster and its associated city truly unique.
    `
  },
  {
    id: 7,
    imgPrev: require('../assets/cards/foundation-b.png').default,
    imgReveal: require('../assets/cards/foundation.png').default,
    dateReveal: 1679428080,
    showDate: false,
    clicks: 0,
    typeReveal: "Clicks",
    canClick: true,
    cardText: `
    Day after day, mystical portals appeared, beckoning powerful and innovative Monsters who materialized in hypersonic pulsarium capsules. 
    As scientists eagerly examined these unearthly vessels, they unraveled a mesmerizing classification of Monsters: Crowns, Pulsarium 1, Pulsarium 2, and Pulsarium 3.
    Intriguingly, their rarity levels were linked to the cities from which they emerged.
    This enchanting discovery captivated the world, as each day brought the thrilling prospect of unearthing new celestial beings and unraveling the secrets they held.
    `
  },
  {
    id: 8,
    imgPrev: require('../assets/cards/global-b.png').default,
    imgPrev2: require('../assets/cards/global-prev.png').default,
    imgReveal: require('../assets/cards/global.png').default,
    minFollowers: 100000,
    canClick: false,
    dateReveal: 1678732205,
    showDate: false,
    child: {
      id: 1,
      dateReveal: 0,
      clicks: 0,
      totalClicks: 0,
      position: "front"
    },
    clicks: 0,
    typeReveal: "Clicks",
    cardText: `
    News of the Monsters arrival spread across the world, people were filled with a sense of wonder and excitement. Reports of sightings began pouring in from cities across the globe. 
    ~As the Monsters began to roam the cities, people started capturing their sightings on their phones and posting them on social media.
    The Social Bird app became the hub for all Monster-related content, and people couldn't get enough of it.
    They shared photos and stories of their encounters with the otherworldly beings, sparking a global phenomenon known as #MonsterMyCity. 
    `
  },
  {
    id: 9,
    imgPrev: require('../assets/cards/composability-b.png').default,
    imgPrev2: require('../assets/cards/composability-prev.png').default,
    imgReveal: require('../assets/cards/composability.png').default,
    dateReveal: 1679215853,
    showDate: false,
    canClick: false,
    child: {
      id: 1,
      dateReveal: 0,
      clicks: 0,
      totalClicks: 0,
      position: "front"
    },
    clicks: 0,
    typeReveal: "Clicks",
    cardText: `
    As the captivating Monsters emerged from their capsules, their featureless forms left the world in awe. 
    Soon, they unveiled an extraordinary secret: the innovative ability to compose the Traits they desired, tailor-making their appearance to integrate with humanity.
    The revelation stirred a sense of wonder and curiosity, as people pondered the mysterious process that enabled these celestial beings to craft their Traits. 
    The enigma only heightened the anticipation of the extraordinary adventures that lay ahead.
    `
  },
  {
    id: 10,
    imgPrev: require('../assets/cards/lab-b.png').default,
    imgPrev2: require('../assets/cards/lab-prev.png').default,
    imgReveal: require('../assets/cards/lab.png').default,
    dateReveal: 1682056856,
    canClick: true,
    showDate: false,
    clicks: 0,
    typeReveal: "Clicks",
    showProgress: false,
    buttonLink: {
      url: 'https://lab.monstermy.city/',
      icon: "",
      text: "Monster Lab"
    },
    cardText: `
    Now exploring cities across Earth, the Monsters swiftly construct the innovative Monsters Lab, designed to transform themselves with distinctive traits and attributes. 
    This cutting edge facility ensures each Monster is truly one of a kind. So all the earthlings can tell them apart!
    ~Enter the MonstersLab below 👇
    `
  },
  {
    id: 11,
    imgPrev: require('../assets/cards/gateKeepers1.png').default,
    imgPrev2: require('../assets/cards/gateKeepers2.png').default,
    imgReveal: require('../assets/cards/gateKeepers3.png').default,
    dateReveal: 1682056856,
    showDate: false,
    canClick: false,
    child: {
      id: 1,
      dateReveal: 0,
      clicks: 0,
      totalClicks: 0,
      position: "front"
    },
    clicks: 0,
    typeReveal: "Clicks",
    cardText: `
    Only 100,000 Gatekeepers, with powerful rare abilities, are summoned through the mystical portals, making them the most scarce force in the universe.
    `
  },
  {
    id: 12,
    imgPrev: require('../assets/cards/powers1.png').default,
    imgPrev2: require('../assets/cards/powers2.png').default,
    imgReveal: require('../assets/cards/powers3.png').default,
    dateReveal: 1683831600,
    showDate: false,
    canClick: true,
    clicks: 0,
    typeReveal: "Clicks",
    cardText: `
    Gatekeepers possess varied powers; Crowns reign supreme in the Monster Realm. Among them, P1 Monsters are more powerful than P2 and P3 Monsters.
    `
  },
  {
    id: 13,
    imgPrev: require('../assets/cards/bearerOfFruit1.png').default,
    imgPrev2: require('../assets/cards/bearerOfFruit2.png').default,
    imgReveal: require('../assets/cards/bearerOfFruit3.png').default,
    dateReveal: 1683918000,
    showDate: false,
    canClick: true,
    clicks: 0,
    typeReveal: "Clicks",
    cardText: `
    "Don't judge a tree by its appearance, but rather by the fruit it bears."
    `
  },
  {
    id: 14,
    imgPrev: require('../assets/cards/theRoadAhead1.png').default,
    imgPrev2: require('../assets/cards/theRoadAhead2.png').default,
    imgReveal: require('../assets/cards/theRoadAhead3.png').default,
    dateReveal: 1684004400,
    showDate: false,
    canClick: true,
    clicks: 0,
    typeReveal: "Clicks",
    cardText: `
    "We are not bound by the same rules as humans and can therefore find new and innovative ways to solve problems."
    `
  },
  {
    id: 15,
    imgPrev: require('../assets/cards/monsterMyCity1.png').default,
    imgPrev2: require('../assets/cards/monsterMyCity2.png').default,
    imgReveal: require('../assets/cards/monsterMyCity3.png').default,
    dateReveal: 1684080000,
    showDate: false,
    canClick: true,
    clicks: 0,
    typeReveal: "Clicks",
    cardText: `
    `,
    buttonLink: {
      url: 'https://twitter.com/MonsterMyCity/status/1657778001482772483',
      icon: "",
      text: "Its Time"
    }
  },
  {
    id: 16,
    imgPrev: require('../assets/cards/cosmic1.png').default,
    imgPrev2: require('../assets/cards/cosmic2.png').default,
    imgReveal: require('../assets/cards/cosmic3.png').default,
    dateReveal: 1683844765,
    showDate: false,
    canClick: true,
    clicks: 0,
    typeReveal: "Clicks",
    cardText: `
    Amazed by humanity's ingenuity with tools and their widespread use of smartphones, 
    the Monsters devised an exciting plan. 
    ~They set out to create a groundbreaking mobile app, enabling them to share their cutting edge advanced AI.
    `
  },
  {
    id: 17,
    imgPrev: require('../assets/cards/download1.png').default,
    imgPrev2: require('../assets/cards/download2.png').default,
    imgReveal: require('../assets/cards/download2.png').default,
    dateReveal: 1683844765,
    showDate: false,
    canClick: true,
    clicks: 0,
    typeReveal: "CountDown",
    cardText: `
    `
  },
  {
    id: 18,
    imgPrev: require('../assets/cards/incubation1.png').default,
    imgPrev2: require('../assets/cards/incubation2.png').default,
    imgReveal: require('../assets/cards/incubation2.png').default,
    dateReveal: 1683844765,
    showDate: false,
    canClick: true,
    clicks: 0,
    typeReveal: "CountDown",
    cardText: `
    `
  },
  {
    id: 19,
    imgPrev: require('../assets/cards/hatched1.png').default,
    imgPrev2: require('../assets/cards/hatched2.png').default,
    imgReveal: require('../assets/cards/hatched2.png').default,
    dateReveal: 1683844765,
    showDate: false,
    canClick: false,
    child: {
      id: 1,
      dateReveal: 0,
      clicks: 0,
      totalClicks: 0,
      position: "front"
    },
    clicks: 0,
    typeReveal: "Clicks",
    cardText: `
    `
  },
  {
    id: 1,
    imgPrev: require('../assets/cards/confidential.png').default,
    imgReveal: require('../assets/cards/global.png').default,
    clicks: 0,
    cardText: ``
  },
  {
    id: 1,
    imgPrev: require('../assets/cards/confidential.png').default,
    imgReveal: require('../assets/cards/global.png').default,
    clicks: 0,
    cardText: ``
  },
  {
    id: 1,
    imgPrev: require('../assets/cards/confidential.png').default,
    imgReveal: require('../assets/cards/global.png').default,
    clicks: 0,
    cardText: ``
  },
]

const config = {
  cards: cards
};

export default config;
