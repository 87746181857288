import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(i18n.language);

  const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    setSelectedLanguage(selectedLanguage);
  };

  useEffect(() => {
    // Update the selectedLanguage state when the language changes from outside the component
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <div>
      <select id="language-select" onChange={changeLanguage} value={selectedLanguage}
      style={{
          backgroundColor: 'black', // Set black background color
          color: 'white', // Set white text color
          padding: '10px',
          fontSize: '16px',
          border: 'none',
          borderRadius: '4px',
          fontWeight: 'bold',
          
        }}>
        <option value="en">{t('english')}</option>
        <option value="es">{t('spanish')}</option>
        <option value="zh">{t('chinese')}</option>
      </select>
    </div>
  );
};

export default LanguageSelector;
