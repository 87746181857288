import React from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { IAlert } from '../../types';
import { setAlert } from '../../store/actions/Alert';
import { Alert, Grow, Button, Slide } from '@mui/material';
import { useAlert } from '../../hooks/useAlert';

const CustomAlert = () => {
  const _alert = useAlert();
  const { ...alert } = _alert as any;

  const dispatch: Dispatch<any> = useDispatch();
  const _setAlert = React.useCallback(
    (alert: IAlert) => dispatch(setAlert(alert)),
    [dispatch]
  );

  const timeToCloseAlert = () => {
    setTimeout(() => {
      _setAlert({ visible: false, message: '', type: '', title: '' });
    }, 15000);
  };

  React.useEffect(() => {
    if (alert.alert.visible) {
      timeToCloseAlert();
    }
  }, [alert]);

  return (
    alert.alert.visible && (
      <Slide
        direction='down'
        in={alert.alert.visible}
        mountOnEnter
        unmountOnExit
      >
        <Alert
          onClick={() =>
            _setAlert({ visible: false, message: '', type: '', title: '' })
          }
          severity={alert.alert.type}
          className='alert__container'
          onClose={() =>
            _setAlert({ visible: false, message: '', type: '', title: '' })
          }
        // action={
        //   toast.toast.type === 'success' && (
        //     <Button
        //       href={toast.toast.message}
        //       target='_blank'
        //       rel='noreferrer'
        //       color='inherit'
        //       size='small'
        //     >
        //       View in Etherscan
        //     </Button>
        //   )
        // }
        >
          <h4>{alert.alert.title}</h4>
          {alert.alert.type === 'success'
            ? 'Transaction Completed'
            : alert.alert.message}
        </Alert>
      </Slide>
    )
  );
};

export default CustomAlert;
