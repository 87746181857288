import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import alertReducer from './alertReducer';
import toastReducer from './toastReducer';
import rolesReducer from './rolesReducer';
import nftsReducer from './nftsReducer';

import { State } from '../../types/index';

const reducers = combineReducers<State>({
  account: accountReducer,
  alert: alertReducer,
  toast: toastReducer,
  role: rolesReducer,
  nfts: nftsReducer,
});

export default reducers;
