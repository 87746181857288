import { ToastState, IToast } from '../types';
import { useSelector, shallowEqual } from 'react-redux';

export const useToast = () => {
  const message: IToast = useSelector(
    (state: ToastState) => state.toast,
    shallowEqual
  );

  return message;
};
