export enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,
  LOCAL = 31336,
}

export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  SupportedChainId.MAINNET,
  SupportedChainId.ROPSTEN,
  SupportedChainId.RINKEBY,
  SupportedChainId.GOERLI,
  SupportedChainId.KOVAN,
  SupportedChainId.LOCAL,
];

export const POLLING_INTERVAL = 12000;

export const RPC_URLS: { [chainId in SupportedChainId]: string } = {
  [SupportedChainId.MAINNET]:
    'https://mainnet.infura.io/v3/b111d8f387c847039541e29435e06cd2',
  [SupportedChainId.ROPSTEN]:
    'https://ropsten.infura.io/v3/b9c9c8f8b9f64f0c9d3e9f0f8e9c8dab',
  [SupportedChainId.RINKEBY]:
    'https://rinkeby.infura.io/v3/b9c9c8f8b9f64f0c9d3e9f0f8e9c8dab',
  [SupportedChainId.GOERLI]:
    'https://goerli.infura.io/v3/417a9dedd72448ddac49648d63058b3f',
  [SupportedChainId.KOVAN]:
    'https://kovan.infura.io/v3/b111d8f387c847039541e29435e06cd2',
  [SupportedChainId.LOCAL]: 'http://localhost:8545',
};


export const UNISWAP_V2_ROUTER = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
export const UNISWAP_V2_FACTORY = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f';