import magicEdenLogo from "../../assets/magicEdenWhite.png";
import { FaTwitter } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { LanguageSelector } from '../organisms';

const WalletCard = (props: any) => {

  return (
    <nav className='navbar'>
      <ul className='navbar__left'>
        <li className='logo'>
          <img src={require('../../assets/M2logo.png').default} alt="" />
          <a href="https://monstermy.city/" rel="noopener noreferrer">
          </a>
        </li>
        <li>
          <div className='menu'>
            <a href="https://opes.ai" rel="noopener noreferrer">
              <div className='link'>
                <small>
                  <img height={25} src={require('../../assets/opesai.png').default} alt="" />
                </small>
              </div>
            </a>
          </div>
        </li>
        <li>
          <div className='menu'>
            <a href="https://rank.monstermy.city/" rel="noopener noreferrer">
              <div className='link'>
                <small>
                  Rank
                </small>
              </div>
            </a>
          </div>
        </li>
        <li>
          <div className='menu'>
            <a href="https://mint.monstermy.city/" rel="noopener noreferrer">
              <div className='link'>
                <small>
                  Mint
                </small>
              </div>
            </a>
          </div>
        </li>
        <li>
          <div className='menu'>
            <a href="https://lab.monstermy.city/" rel="noopener noreferrer">
              <div className='link'>
                <small>
                  Lab
                </small>
              </div>
            </a>
          </div>
        </li>
        {/*<li>
          <div className='menu'>
            <a href="https://opes.ai" target="_blank" rel="noopener noreferrer">
              <div className='link'>
                <small>
                  OpesAI
                </small>
              </div>
            </a>
          </div>
        </li>*/}
        <li>
          <div className='menu'>
            <a href="https://crowns.monstermy.city" rel="noopener noreferrer">
              <div className='link'>
                <small>
                  Crowns
                </small>
              </div>
            </a>
          </div>
        </li>
        <li>
          <div className='menu'>
            <a href="https://lore.monstermy.city" rel="noopener noreferrer">
              <div className='link'>
                <small>
                  Lore
                </small>
              </div>
            </a>
          </div>
        </li>
        {props.id !== "chasers" &&
          <li>
            <div className='menu'>
              <a href="https://connectricity.monstermy.city/welcome" target='_blank' rel="noopener noreferrer">
                <div className='link'>
                  <small>
                    Game
                  </small>
                </div>
              </a>
            </div>
          </li>
        }
        {props.id === "chasers" &&
          <li>
            <div className='menu'>
              <div className='selected'>
                Code Chasers
              </div>
            </div>
          </li>
        }
        <li>
          <div className='icons'>
            <a href="https://twitter.com/MonsterMyCity" target="_blank" rel="noopener noreferrer">
              <div className='link'>
                <FaXTwitter size={15} />
              </div>
            </a>
          </div>
        </li>
        <li>
          <div className='icons'>
            <a href="https://magiceden.io/collections/ethereum/0x81521a9cea2aeea9cdde68a3ff02d1943ab59ebd" target="_blank" rel="noopener noreferrer">
              <div className='link'>
                <img src={magicEdenLogo} alt="" />
              </div>
            </a>
          </div>
        </li>
        <li>
          <LanguageSelector />
        </li>
      </ul>
    </nav>
  );
};

export default WalletCard;
