import { slide as Menu } from "react-burger-menu";
import { useState, useCallback, useEffect, FC } from "react";
import { Button } from "../atoms";
import { RiTwitterFill, RiDiscordFill } from "react-icons/ri";
import { FaYoutube } from "react-icons/fa";
import { AiFillMediumSquare } from "react-icons/ai";
import { ReactComponent as OpenSeaLogo } from "../../assets/opensea-logo.svg";
import { ReactComponent as Logo } from "../../assets/bees-logo.svg";
import magicEdenLogo from "../../assets/magicEdenWhite.png";
import { FaTwitter } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

import { useDispatch } from "react-redux";
import { setAccount } from "../../store/actions/account";
import { WalletModal } from "../molecules";
import { IAccount } from "../../types";
import { Dispatch } from "redux";
import { useAccount } from "../../hooks/useAccount";
import { useGetEthBalance } from "../../hooks/useGetEthBalance";
import { parseNumbers } from "../../utils/currency";
import { LanguageSelector } from '../organisms';

const SideBar = (props: any) => {
  let _account: IAccount = useAccount();
  const { ...account } = _account as any;
  const [ethBalance, setEthBalance] = useState("0.0");
  const dispatch: Dispatch<any> = useDispatch();
  const balance = useGetEthBalance();
  const _setAccount = useCallback(
    (account: IAccount) => dispatch(setAccount(account)),
    [dispatch]
  );
  const handleWalletModal = () => setOpen(!open);
  const [open, setOpen] = useState(false);

  async function _setEthBalance() {
    const b: any = await balance;
    setEthBalance(parseNumbers(b));
  }

  useEffect(() => {
    if (account.account.address !== "") {
      _setEthBalance();
    }
  });

  return (
    // Pass on our props
    <Menu {...props} right>
      <div className='sidebar'>
        <ul>
          <li>
            <div className='menu'>
              <a href="https://opes.ai" rel="noopener noreferrer">
                <img height={25} width={80} src={require('../../assets/opesai.png').default} alt="" />
              </a>
            </div>
          </li>
          <li>
            <div className='menu'>
              <a href="https://rank.monstermy.city/" rel="noopener noreferrer">
                <div className='link'>
                  <small>
                    Rank
                  </small>
                </div>
              </a>
            </div>
          </li>
          <li>
            <div className='menu'>
              <a href="https://mint.monstermy.city/" rel="noopener noreferrer">
                <div className='link'>
                  <small>
                    Mint
                  </small>
                </div>
              </a>
            </div>
          </li>
          <li>
            <div className='menu'>
              <a href="https://lab.monstermy.city/" rel="noopener noreferrer">
                <div className='link'>
                  <small>
                    Lab
                  </small>
                </div>
              </a>
            </div>
          </li>
          {/*<li>
          <div className='menu'>
            <a href="https://opes.ai" target="_blank" rel="noopener noreferrer">
              <div className='link'>
                <small>
                  OpesAI
                </small>
              </div>
            </a>
          </div>
        </li>*/}
          <li>
            <div className='menu'>
              <a href="https://crowns.monstermy.city" rel="noopener noreferrer">
                <div className='link'>
                  <small>
                    Crowns
                  </small>
                </div>
              </a>
            </div>
          </li>
          <li>
            <div className='menu'>
              <a href="https://lore.monstermy.city" rel="noopener noreferrer">
                <div className='link'>
                  <small>
                    Lore
                  </small>
                </div>
              </a>
            </div>
          </li>
          {props.outerContainerId !== "chasers" &&
            <><li>
              <div className='menu'>
                <a href="https://connectricity.monstermy.city/welcome" target='_blank' rel="noopener noreferrer">
                  <div className='link'>
                    <small>
                      Game
                    </small>
                  </div>
                </a>
              </div>
            </li><br /></>
          }
          {props.outerContainerId === "chasers" &&
            <><li>
              <div className='menu'>
                <div className='selected'>
                  Code Chasers
                </div>
              </div>
            </li><br /></>
          }
          <li>
            <div className='menu'>
              <a href="https://twitter.com/MonsterMyCity" target="_blank" rel="noopener noreferrer">
                <div className='link'>
                  <FaXTwitter />
                </div>
              </a>
            </div>
          </li>
          <li>
            <div className='menu opensea'>
              <a href="https://magiceden.io/collections/ethereum/0x81521a9cea2aeea9cdde68a3ff02d1943ab59ebd" target="_blank" rel="noopener noreferrer">
                <div className='link'>
                  <img src={magicEdenLogo} alt="" />
                </div>
              </a>
            </div>
          </li>
          <li>
            <div className='languageSelector'>
              <LanguageSelector />
            </div>
          </li>
        </ul>
      </div>
    </Menu>
  );
};

export default SideBar;
