import { AlertState } from '../../types';

const alertState: AlertState = {
  alert: {
    visible: false,
    message: '',
    title: '',
    type: '',
  },
};

export default alertState;
