import Web3 from 'web3';
import { useAccount } from '../hooks/useAccount';

export const useGetEthBalance = async () => {
  const _account = useAccount();
  const { ...account } = _account as any;
  // console.log(account.account.provider);

  const web3: Web3 = new Web3(account.account.provider);

  if (!web3 || !account.account.address) return 0;

  try {
    const balance = await web3.eth.getBalance(
      web3.utils.toChecksumAddress(account.account.address)
    );
    return parseFloat(web3.utils.fromWei(balance, 'ether')).toFixed(4);
  } catch (error) {
    console.error('error', error);
    return 0;
  }
};
