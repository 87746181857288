import { AlertState, IAlert } from '../../types';
import alertState from '../states/alert';
import { SET_ALERT } from '../actions/actionTypes';

const STORE_INIT_CONSTANTS = {
  alert: alertState,
};

const messageReducer = (
  Success: AlertState = STORE_INIT_CONSTANTS.alert,
  action: { type: string; payload: IAlert }
): AlertState => {
  switch (action.type) {
    case SET_ALERT:
      return { alert: action.payload };
    default:
      break;
  }
  return Success;
};

export default messageReducer;
