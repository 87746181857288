import * as actionTypes from './actionTypes';
import { ToastAction, DispatchToastType, IToast } from '../../types';

export function setToast(toast: IToast) {
  const action: ToastAction = {
    type: actionTypes.SET_TOAST,
    payload: toast,
  };

  return (dispatch: DispatchToastType) => {
    dispatch(action);
  };
}
