export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_BLOCKCHAIN = 'SET_BLOCKCHAIN';
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const SET_FARM_POOLS_DATA = 'SET_FARM_POOLS_DATA';
export const SET_REWARD_POOLS_DATA = 'SET_REWARD_POOLS_DATA';
export const SET_POOL_IN_TOKENS_DATA = 'SET_POOL_IN_TOKENS_DATA';
export const SET_LP_TOKENS_DATA = 'SET_LP_TOKENS_DATA';
export const SET_TOAST = 'SET_TOAST';
export const SET_ALERT = 'SET_ALERT';
export const SET_ROLE = 'SET_ROLE';
export const SET_NFTS = 'SET_NFTS';