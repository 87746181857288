import * as React from 'react';
import { Toast, Alert } from './components/atoms';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Chasers, Home, Main } from './components/pages';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-6KYJ26JRJ6"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname);
const App: React.FC = () => {

  return (
    <>
      <Alert />
      <Toast />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          {/* <Route path="/chasers" element={<Chasers />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
