import { useMediaQuery } from "react-responsive";
import { WalletCard, SideBar, Artists } from '../organisms';
import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './../../i18n';
import { useState } from 'react';

const Home = (props: any) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 880 });
  const { t } = useTranslation();
  const [videoVisible, setVideoVisible] = useState(false);

  const handlePlayButtonClick = () => {
    setVideoVisible(true);
  };

  const handleModalClose = () => {
    setVideoVisible(false);
  };

  return (
    <I18nextProvider i18n={i18n}>
      <div className='home'>
        {isTabletOrMobile && (
          <SideBar pageWrapId={"page-wrap"} outerContainerId={"home"} />
        )}
        <WalletCard />

        <section id="main-section">
          <div id="title"><span>EmpowerUnity</span></div>
          <div id="subtitle">
            <span>{t('s1')}</span>
            <span>{t('s2')}</span>
            <span>{t('s3')}</span>
            <span>{t('s4')}</span>
            <span>{t('s5')}</span>
            <span>{t('s6')}</span>
            <span>{t('s7')}</span>
            <span>{t('s8')}</span>
            <span>{t('s9')}</span>
          </div>
          <button className="play-button" onClick={handlePlayButtonClick}>
            <img src={require('../../assets/play.png').default} />
          </button>

        </section>

        {videoVisible && (
          <div className="video-modal">
            <button className="close-button" onClick={handleModalClose}>
              Close
            </button>
            <div className="modal-content">
              <video controls autoPlay>
                <source src="https://monstermycity-images.s3.amazonaws.com/partyProtocol.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        )}

        <section id="section-container">
          <div id="section-title">{t('Redefining Value:')}</div>
          <div id="section-subtitle">
            {t('Empowering People with the Power They Deserve')}
          </div>
          <div id="section-text">
            {t('We call upon creators, consumers, and everyone in between to join us in this digital renaissance.')}<br /><br />
            {t('Embrace the Web3 revolution.')} <br /><br />
            {t("We propose a new paradigm. One where the 'user' is no longer a commodity to be mined but a valued participant, an essential piece of the puzzle.")} <br /><br />
            {t('We are not the product; we are the revolution.')}<br /><br />
          </div>
          <div id="section-title">{t("Let's")}</div>
          <div className="row" id="lets">
            <div className="col-md-5 col-sm-5 mb-3 mb-md-0 border-left" ><div>
              {t('celebrate our unique identities and shared experiences.')}
            </div></div>
            <div className="col-md-3 col-sm-3 mb-3 mb-md-0 border-left"><div>
              {t('redefine what value means.')}
            </div></div>
            <div className="col-md-4 col-sm-4 border-left"><div>
              {t('shape our digital future - together, for the culture.')}
            </div></div>
          </div>
          <div> </div>
        </section>

        <section id="monster-section">
          <div id="monters-image"><img src={require('../../assets/monster.png').default} /></div>
          <div id="monsters">
            {t('Monsters symbolize EmpowerUnity, uniting people against exploitation in the next version of the world wide web, Web3; no entity owns them, they belong to the collective spirit of empowerment.')}
          </div>
        </section>

        <section id="section-joinus">
          <div id="section-title">{t("Do these Creators oppose the exploitation of people's value in the current digital economy and endorse an inclusive approach to value creation in the upcoming Web3 era?")}</div>
          <br />
          <div id="join-us">{t('Join us to discover their stance.')}</div>
          <div id="section-text">
            <div className="border-left">{t('Every individual below that publicly endorses the EmpowerUnity Movement by signaling to the Monsters their support will unlock a unique code.')}</div><br /><br />
            <div className="border-left">{t('The code will grant someone the privilege of minting a free Monster NFT as a tribute to their support. The chosen city will become a beacon for embracing our Web3 vision of empowerment.')}</div><br /><br />
            <div className="border-left">{t("Let the discovery process begin, and let's identify those who align with this cultural shift towards a more inclusive Web3.")}<span className="gray"> #EmpowerUnity</span></div>
          </div>
        </section>

        <section id="section-joinus">         
          <Artists />
        </section>

        <div id="footer"></div>

      </div>
    </I18nextProvider>
  );
};

export default Home;
