import * as actionTypes from './actionTypes';
import { AccountAction, DispatchAccountType, IAccount } from '../../types';

export function setAccount(account: IAccount) {
  const action: AccountAction = {
    type: actionTypes.SET_ACCOUNT,
    payload: account,
  };

  return (dispatch: DispatchAccountType) => {
    dispatch(action);
  };
}
