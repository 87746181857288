import { ToastState } from '../../types';

const toastState: ToastState = {
  toast: {
    visible: false,
    message: '',
    type: '',
    openseaLink: '',
    etherLink: '',
  },
};

export default toastState;
