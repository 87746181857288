import { ToastState, IToast } from '../../types';
import toastState from '../states/toast';
import { SET_TOAST } from '../actions/actionTypes';

const STORE_INIT_CONSTANTS = {
  toast: toastState,
};

const messageReducer = (
  Success: ToastState = STORE_INIT_CONSTANTS.toast,
  action: { type: string; payload: IToast }
): ToastState => {
  switch (action.type) {
    case SET_TOAST:
      return { toast: action.payload };
    default:
      break;
  }
  return Success;
};

export default messageReducer;
