import React from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { IToast } from '../../types';
import { setToast } from '../../store/actions/Toast';
import { Alert, Grow, Button } from '@mui/material';
import { useToast } from '../../hooks/useToast';

const Toast = () => {
  const _toast = useToast();
  const { ...toast } = _toast as any;

  const dispatch: Dispatch<any> = useDispatch();
  const _setToast = React.useCallback(
    (toast: IToast) => dispatch(setToast(toast)),
    [dispatch]
  );

  const timeToCloseAlert = () => {
    setTimeout(() => {
      _setToast({ visible: false, message: '', type: '' });
    }, 10000);
  };

  React.useEffect(() => {
    if (toast.toast.visible) {
      timeToCloseAlert();
    }
  }, [toast]);

  return (
    toast.toast.visible && (
      <Grow in={toast.toast.visible}>
        <Alert
          onClick={() => _setToast({ visible: false, message: '', type: '' })}
          severity={toast.toast.type}
          className='toast__container'
          onClose={() => _setToast({ visible: false, message: '', type: '' })}
        // action={
        //   toast.toast.type === 'success' && (
        //     <Button
        //       href={toast.toast.message}
        //       target='_blank'
        //       rel='noreferrer'
        //       color='inherit'
        //       size='small'
        //     >
        //       View in Etherscan
        //     </Button>
        //   )
        // }
        >
          {toast.toast.type === 'success'
            ? toast.toast.message
            : toast.toast.message}

          {toast.toast.etherLink && (
            <a
              href={toast.toast.etherLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              View on Etherscan
            </a>
          )}

          {toast.toast.openseaLink && (
            <a
              href={toast.toast.openseaLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              View on Opensea
            </a>
          )}
        </Alert>
      </Grow>
    )
  );
};

export default Toast;
