import { NFTSState, INFTS } from '../../types';
import nftsState from '../states/nfts';
import { SET_NFTS } from '../actions/actionTypes';

const STORE_INIT_CONSTANTS = {
  nfts: nftsState,
};

const nftsReducer = (
  nfts: NFTSState = STORE_INIT_CONSTANTS.nfts,
  action: { type: string; payload: INFTS }
): NFTSState => {
  switch (action.type) {
    case SET_NFTS:
      return { nfts: action.payload };
    default:
      break;
  }
  return nfts;
};

export default nftsReducer;
