import * as actionTypes from './actionTypes';
import { AlertAction, DispatchAlertType, IAlert } from '../../types';

export function setAlert(alert: IAlert) {
  const action: AlertAction = {
    type: actionTypes.SET_ALERT,
    payload: alert,
  };

  return (dispatch: DispatchAlertType) => {
    dispatch(action);
  };
}
