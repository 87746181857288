export const parseNumbers = (b: any) => {
  // number needs to be 9 digits including decimals
  let parsed = parseFloat(b).toLocaleString('en-US', {
    minimumFractionDigits: 9,
    maximumFractionDigits: 9,
  });

  const integer = parseInt(parsed.replace(/,/g, ''));
  if (integer > 1000000000 && integer < 10000000000) {
    parsed = parseFloat(b).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else if (integer > 10000000 && integer < 100000000) {
    parsed = parseFloat(b).toLocaleString('en-US', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });
  } else if (integer >= 1000000 && integer < 10000000) {
    parsed = parseFloat(b).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else if (integer >= 100000 && integer < 1000000) {
    parsed = parseFloat(b).toLocaleString('en-US', {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });
  } else if (integer >= 10000 && integer < 100000) {
    parsed = parseFloat(b).toLocaleString('en-US', {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
  } else if (integer >= 1000 && integer < 10000) {
    parsed = parseFloat(b).toLocaleString('en-US', {
      minimumFractionDigits: 5,
      maximumFractionDigits: 5,
    });
  } else if (integer >= 100 && integer <= 1000) {
    parsed = parseFloat(b).toLocaleString('en-US', {
      minimumFractionDigits: 6,
      maximumFractionDigits: 6,
    });
  } else if (integer > 10 && integer < 100) {
    parsed = parseFloat(b).toLocaleString('en-US', {
      minimumFractionDigits: 7,
      maximumFractionDigits: 7,
    });
  } else {
    parsed = parseFloat(b).toLocaleString('en-US', {
      minimumFractionDigits: 8,
      maximumFractionDigits: 8,
    });
  }
  return parsed;
};
